import { StyledModal } from 'components/styled/StyledModal';
import { Session, Chatbox, Inbox } from '@talkjs/react';
import { ReactComponent as ChatLoading } from 'assets/icons/waiting.svg';
import TalkJS from 'pages/talk-js';
import * as T from 'pages/talk-js/style';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useAuth } from 'helpers/contexts/auth-context';
import { talkjsApiKey } from 'helpers/utils/helper';

interface Prop {
  show: boolean;
  closeModal: () => void;
  conversationId: string;
  freelancerName: string;
  theme: 'proposal' | 'invite';
}

const ChatModal = ({ show, closeModal, conversationId, freelancerName, theme }: Prop) => {
  const { themes } = useSelector((state: RootState) => state.talkJsChat);
  const { user } = useAuth();

  const loadingComponent = (
    <T.Loading>
      <div className="d-flex align-items-center justify-content-center gap-4">
        <ChatLoading />
        <p>Your chat is loading...</p>
      </div>
    </T.Loading>
  );

  return (
    <StyledModal maxwidth={767} show={show} size="sm" onHide={closeModal} centered>
      {freelancerName && (
        <Modal.Header>
          <p className="mb-0 fs-5" style={{ fontWeight: 500 }}>
            {freelancerName}
          </p>
        </Modal.Header>
      )}

      <Modal.Body style={{ padding: '1rem', height: '80vh' }}>
        {!conversationId && <div>No Chat found.</div>}

        {conversationId && (
          <T.Chatbox style={{ height: '100%' }}>
            <Session desktopNotificationEnabled appId={talkjsApiKey()} userId={user.user_id}>
              <Chatbox
                // messageField={{ visible: !sendMessageDisabledText }}
                showChatHeader={false}
                loadingComponent={loadingComponent}
                theme={themes[theme]}
                style={{ height: '100%' }}
                conversationId={conversationId}
              />
            </Session>
            <Inbox />
          </T.Chatbox>
        )}
      </Modal.Body>
    </StyledModal>
  );
};

export default ChatModal;
