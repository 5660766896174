/*
 * This is the main component of this route
 */
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Wrapper } from './support.styled';
import DisputeForm from './DisputeForm';
import GeneralInquiryForm from './GeneralInquiryForm';
import Tabs from 'components/ui/Tabs';
import useStartPageFromTop from 'helpers/hooks/useStartPageFromTop';
import FaqForm from './FaqForm';

// These are the 2 tabs that will be displayed on the support page
const TABS = [
  { id: 0, label: 'General Inquiry', key: 'gen_inquiry' },
  { id: 1, label: 'FAQs', key: 'faq' },
  { id: 2, label: 'Submit Dispute', key: 'submit_dispute' },
  //FAQs
];

const Support = () => {
  const { type: activeTab = 'gen_inquiry' } = useParams();
  const navigate = useNavigate();
  /* This will start the page top (Scroll position) */
  useStartPageFromTop();

  const onTabChange = (tab: string) => {
    navigate(`/support/${tab}`);
  };

  return (
    <Wrapper className="my-3 content-hfill">
      <div className="content">
        {/* Title */}
        <h1 className="page-title text-center fw-400">Customer Support</h1>

        {/* Tabs */}
        <div className="my-3 d-flex justify-content-center">
          <Tabs activeTab={activeTab} tabs={TABS} onTabChange={onTabChange} className="d-flex justify-content-center" />
        </div>

        {/* Forms */}
        <div className="dispute-form">
          {activeTab === 'gen_inquiry' && <GeneralInquiryForm />}
          {activeTab === 'submit_dispute' && <DisputeForm />}
          {activeTab === 'faq' && <FaqForm />}
        </div>
      </div>

      {/* Dispute button */}
      {activeTab === 'submit_dispute' && (
        <Link to="/disputes">
          <div className="view-dispute-btn text-center pointer fs-18 fw-400">Open Dispute History</div>
        </Link>
      )}
    </Wrapper>
  );
};

export default Support;
