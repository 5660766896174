import { Session, Chatbox, Inbox } from '@talkjs/react';
import * as T from './style';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'helpers/contexts/auth-context';
import SingleUser from './singleUser';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { ReactComponent as ChatLoading } from 'assets/icons/waiting.svg';
import { ReactComponent as MessageIcon } from 'assets/icons/MessageIcon.svg';
import { AppDispatch, RootState } from 'redux/store';
import { fetchMyConversation, resetConversation, singleConversationById } from '../../redux/slices/talkjsSlice';
import ChatFilter from './chatFilter';
import ChatNavbar from './chatNavbar';
import useResponsive from 'helpers/hooks/useResponsive';
import { formatDate } from 'helpers/utils/formatter';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { ChatUser } from 'redux/slices/talkjs.interface';
import { useParams } from 'react-router-dom';
import { isStagingEnv, talkjsApiKey } from 'helpers/utils/helper';
import { talkJSAccessTokenApi } from 'helpers/http/common';
// import jwt from 'jsonwebtoken';

interface Prop {
  singleConversation?: string;
}
const TalkJS = ({ singleConversation }: Prop) => {
  const [open, setOpen] = useState<boolean>(true);
  const [chatAuth, setChatAuth] = useState<{ token: string; loading: boolean }>({ loading: false, token: '' });
  const [showChatFilter, setShowChatFilter] = useState<boolean>(true);
  const { chatlist, loading, selectedConversation, filters, themes } = useSelector(
    (state: RootState) => state.talkJsChat
  );
  const { isDesktop } = useResponsive();
  const { user } = useAuth();
  const { conversationId } = useParams();

  const dispatch: AppDispatch = useDispatch();

  const loadingComponent = (
    <T.Loading>
      <div className="d-flex align-items-center justify-content-center gap-4">
        <ChatLoading />
        <p>Your chat is loading...</p>
      </div>
    </T.Loading>
  );

  const isClosedorDeclined = (conversation: ChatUser) => {
    if (conversation?.custom && !conversation?.custom?.payload) return '';

    const payload = conversation.custom.payload;
    if (payload?.proposal_status || payload?.invite_status)
      if (['deleted', 'active', 'closed'].includes(payload?.job_status)) {
        return 'Closed';
      } else if (payload?.proposal_status === 'denied') {
        return 'Declined';
      } else if (payload?.invite_status === 'canceled') {
        return 'Canceled';
      } else if (payload?.invite_status === 'accepted') {
        return 'Accepted';
      }
    return '';
  };

  const sendMessageDisabledText = useMemo(() => {
    if (!selectedConversation) return false;
    const activeChat = selectedConversation.custom.payload;
    const conversationType = selectedConversation.custom.type;

    if (conversationType === 'proposal') {
      switch (activeChat?.job_status) {
        case 'deleted': {
          if (!activeChat?.job_date_modified) return undefined;
          if (user?.user_type === 'freelancer')
            return <span>The client canceled this project post - {formatDate(activeChat.job_date_modified)}</span>;
          return <span>You canceled this project post - {formatDate(activeChat.job_date_modified)}</span>;
        }
        case 'closed':
        case 'active': {
          if (!activeChat?.job_start_date) return undefined;
          if (user?.user_type === 'freelancer' && activeChat?.proposal_status === 'accept') {
            return (
              <span>
                The client accepted your proposal on {formatDate(activeChat.proposal_date_modified)}! This conversation
                has since been moved to the "Projects" tab.{' '}
                <Link
                  style={{ textDecoration: 'underline' }}
                  className="link"
                  to={`/messages-new/${activeChat.job_post_id}`}
                >
                  HERE.
                </Link>
              </span>
            );
          } else if (user?.user_type === 'client' && activeChat?.proposal_status === 'accept') {
            return (
              <span>
                When you accepted this freelancer's project proposal on {formatDate(activeChat.proposal_date_modified)},
                this conversation was moved to the "Projects" tab {''}
                <Link
                  style={{ textDecoration: 'underline' }}
                  className="link"
                  to={`/messages-new/${activeChat.job_post_id}`}
                >
                  HERE.
                </Link>
              </span>
            );
          } else if (user?.user_type === 'freelancer') {
            return (
              <span>
                The client awarded the project to another freelancer - {formatDate(activeChat.job_start_date)}
              </span>
            );
          } else {
            return (
              <span>You awarded this project to another freelancer - {formatDate(activeChat.job_start_date)}</span>
            );
          }
        }
        case 'prospects': {
          if (activeChat?.proposal_status !== 'denied') return undefined;
          if (!activeChat?.proposal_date_modified) return undefined;
          if (user?.user_type === 'freelancer')
            return (
              <span>
                The client has declined your project proposal - {formatDate(activeChat.proposal_date_modified)}
              </span>
            );
          return <span>You declined this proposal - {formatDate(activeChat.proposal_date_modified)}</span>;
        }
        default:
          return undefined;
      }
    }
    if (conversationType === 'invite') {
      switch (activeChat?.job_status) {
        case 'deleted': {
          if (!activeChat?.job_date_modified) return undefined;
          if (user?.user_type === 'freelancer')
            return <span>The client canceled this project post - {formatDate(activeChat.job_date_modified)}</span>;
          return <span>You canceled this project post - {formatDate(activeChat.job_date_modified)}</span>;
        }
        case 'closed':
        case 'active': {
          if (!activeChat?.job_start_date) return undefined;
          if (user?.user_type === 'freelancer')
            return (
              <span>
                The client awarded the project to another freelancer - {formatDate(activeChat.job_start_date)}
              </span>
            );
          return <span>You awarded this project to another freelancer - {formatDate(activeChat.job_start_date)}</span>;
        }
        case 'prospects': {
          if (!activeChat?.invite_date_modified) return undefined;
          if (user?.user_type === 'freelancer' && activeChat?.invite_status == 'accepted') {
            return (
              <span>
                When you submitted a proposal to this project {formatDate(activeChat.invite_date_modified)}, this
                conversation was moved to the "Proposals" tab{' '}
                <Link style={{ textDecoration: 'underline' }} className="link" to={`/messages-new`}>
                  HERE.
                </Link>{' '}
                You can continue to speak to the client there!
              </span>
            );
          } else if (user?.user_type == 'client' && activeChat?.invite_status == 'accepted') {
            return (
              <span>
                The freelancer submitted a proposal to this project on {formatDate(activeChat.invite_date_modified)}, so
                this conversation was moved to the "Proposals" tab.{' '}
                <Link style={{ textDecoration: 'underline' }} className="link" to={`/messages-new`}>
                  HERE.
                </Link>{' '}
                You can continue to speak to them there!
              </span>
            );
          } else if (user?.user_type === 'freelancer' && activeChat?.invite_status == 'canceled') {
            return <span>The client has canceled your invitation - {formatDate(activeChat.invite_date_modified)}</span>;
          } else {
            return <span>You have canceled the invitation. - {formatDate(activeChat.invite_date_modified)}</span>;
          }
        }
        default:
          return undefined;
      }
    }

    /* If the job is closed more than two week, then disable the chat and show the below message ----------------------- START */

    const payload = selectedConversation.custom.payload;
    const todaysDate = moment();
    const jobClosedDate = payload?.job_end_date ? moment(payload?.job_end_date) : moment();
    const daysOfJobClosed = todaysDate.diff(jobClosedDate, 'days');

    if (activeChat?.job_status === 'closed' && activeChat?.job_end_date && daysOfJobClosed >= 14) {
      return (
        <span>Two weeks have passed since this project was completed. The project's message window is now closed.</span>
      );
    }

    /* If the job is closed more than two week, then disable the chat and show the below message ----------------------- END */

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConversation]);

  const chatUsers = useMemo(() => {
    let filteredChats = [...chatlist];

    // selecting a particular job
    if (filters.job !== '') {
      filteredChats = filteredChats.filter((chat) => chat.custom.jobPostId === filters.job);
    }

    if (filters.type !== '') {
      filteredChats = filteredChats.filter((chat) => chat.custom.type === filters.type);
    }

    // changes from  here...
    if (filters.status !== '') {
      if (filters.status === 'open') {
        filteredChats = filteredChats.filter((chat) => !isClosedorDeclined(chat));
      } else {
        filteredChats = filteredChats.filter((chat) => isClosedorDeclined(chat));
      }
    }

    return filteredChats;
  }, [chatlist, filters]);

  const onSelectChat = (conversation: ChatUser) => {
    const conversationId = conversation.id;
    dispatch(singleConversationById(conversationId));
    setOpen(false);
  };

  const totalUnreadMessages = useMemo(
    () => chatlist.reduce((total, chat) => total + chat.unreadMessageCount, 0),
    [chatlist]
  );

  useEffect(() => {
    let controller;

    if (!singleConversation) {
      controller = dispatch(fetchMyConversation(conversationId ?? ''));
    } else {
      dispatch(singleConversationById(singleConversation));
    }

    return () => {
      dispatch(resetConversation());
      if (controller) controller?.abort();
    };
  }, [singleConversation]);

  const talkjsAccessTokenHandler = async () => {
    try {
      setChatAuth({ ...chatAuth, loading: true });

      const response = await talkJSAccessTokenApi();
      if (response && response?.token) {
        setChatAuth({ loading: false, token: response.token });
      } else {
        setChatAuth({ ...chatAuth, loading: false });
      }
    } catch (error) {
      setChatAuth({ ...chatAuth, loading: false });
      throw new Error(error.message);
    }
  };

  const sessionParams = useMemo(() => {
    if (isStagingEnv()) return {};

    return { token: chatAuth.token };
  }, [chatAuth]);

  useEffect(() => {
    if (user.user_id && !isStagingEnv()) talkjsAccessTokenHandler();
  }, [user]);

  return (
    <T.Wrapper className="container">
      {!singleConversation && (
        <T.Sidebar openState={open ? 'open' : ''}>
          <T.ChatListHeader>
            <p className="inbox-title">Inbox {totalUnreadMessages > 0 ? `(${totalUnreadMessages})` : ''}</p>
            <Link className="" to="/messages">
              view older chats
            </Link>
          </T.ChatListHeader>
          {loading && chatlist.length === 0 && (
            <div className="d-flex align-items-center justify-content-center gap-2 mt-5 fetching-chat-loader">
              <Spinner animation="border" size="sm" />
              <p className="mb-0">fetching chatlist...</p>
            </div>
          )}

          {!loading && chatlist.length === 0 && (
            <div className="text-center py-5">
              <p>No Chats Found.</p>
            </div>
          )}
          {(!loading || chatlist.length > 0) &&
            chatUsers.map((conversation, index) => (
              <SingleUser onSelectChat={onSelectChat} conversation={conversation} key={`talkjs-single-user-${index}`} />
            ))}
        </T.Sidebar>
      )}

      <T.Content>
        {!singleConversation && showChatFilter && <ChatFilter />}
        {selectedConversation?.id ? (
          <>
            <ChatNavbar
              singleConversation={singleConversation}
              setOpen={setOpen}
              setShowChatFilter={setShowChatFilter}
            />
            <T.Chatbox>
              {!isStagingEnv() && !chatAuth.token && chatAuth.loading && (
                <div className="text-center">
                  <p className="mt-5 lead">authenticating chat...</p>
                </div>
              )}

              {((chatAuth.token && !chatAuth.loading) || isStagingEnv()) && (
                <Session {...sessionParams} desktopNotificationEnabled appId={talkjsApiKey()} userId={user.user_id}>
                  <Chatbox
                    messageField={{ visible: !sendMessageDisabledText }}
                    showChatHeader={false}
                    loadingComponent={loadingComponent}
                    theme={themes[selectedConversation?.custom?.type] ?? themes['job']}
                    className="talkjs-chatbox"
                    conversationId={selectedConversation.id}
                  />
                </Session>
              )}
              <Inbox />
            </T.Chatbox>
            {/* Chat disable reason message  */}
            {sendMessageDisabledText && <T.DisableChatReason>{sendMessageDisabledText}</T.DisableChatReason>}
          </>
        ) : (
          <section className="text-center d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
            <div>
              <MessageIcon />
              <h5 className="my-4">
                Click on the chat you want to see from the list on the {!isDesktop ? 'top' : 'left'}…
              </h5>
            </div>
          </section>
        )}
      </T.Content>
    </T.Wrapper>
  );
};
export default TalkJS;
